var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_romajiTitle, _default_1_englishTitle, _default_1_updateTitle;
import { Controller } from '@local-stimulus';
import { PreferenceKeys, Titles } from '@root/user/global-preferences';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        _default_1_romajiTitle.set(this, void 0);
        _default_1_englishTitle.set(this, void 0);
    }
    connect() {
        __classPrivateFieldSet(this, _default_1_romajiTitle, this.data.get('romajiTitle'), "f");
        __classPrivateFieldSet(this, _default_1_englishTitle, this.data.get('englishTitle'), "f");
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateTitle).call(this);
    }
}
_default_1_romajiTitle = new WeakMap(), _default_1_englishTitle = new WeakMap(), _default_1_instances = new WeakSet(), _default_1_updateTitle = function _default_1_updateTitle() {
    let userPreferredTitle = __classPrivateFieldGet(this, _default_1_romajiTitle, "f");
    const englishTitle = __classPrivateFieldGet(this, _default_1_englishTitle, "f");
    if (window.Viewer.preferences.get(PreferenceKeys.Titles) === Titles.English && englishTitle) {
        userPreferredTitle = englishTitle;
    }
    this.titleTarget.textContent = userPreferredTitle;
    this.element.title = userPreferredTitle;
};
default_1.targets = ['title'];
