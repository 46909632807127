import { Controller } from '@local-stimulus';
import { PreferenceKeys, Titles } from '@root/user/global-preferences';
const PLACEHOLDER = '%{preferred_title}';
export default class default_1 extends Controller {
    connect() {
        let userPreferredTitle = this.data.get('romaji');
        const englishTitle = this.data.get('english');
        if (window.Viewer.preferences.get(PreferenceKeys.Titles) === Titles.English && englishTitle) {
            userPreferredTitle = englishTitle;
        }
        const textContentTemplate = this.data.get('content-template');
        const titleTemplate = this.data.get('title-template');
        if (textContentTemplate) {
            this.element.textContent = textContentTemplate.replace(PLACEHOLDER, userPreferredTitle);
        }
        if (titleTemplate) {
            this.element.title = titleTemplate.replace(PLACEHOLDER, userPreferredTitle);
        }
    }
}
default_1.targets = ['preferredTitle'];
