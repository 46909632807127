var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_isScrollable_get, _default_1_carouselWindowWidth_get, _default_1_updateControlsInternal, _default_1_setPreviousControlVisible, _default_1_setNextControlVisible, _default_1_canGoPrevious_get, _default_1_canGoNext_get;
import { Controller } from '@local-stimulus';
import vebounce from '@lib/vebounce';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        this.updateControls = vebounce(__classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateControlsInternal).bind(this), 25);
    }
    connect() {
        this.element[this.identifier] = this;
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateControlsInternal).call(this);
    }
    goPrevious() {
        if (__classPrivateFieldGet(this, _default_1_instances, "a", _default_1_canGoPrevious_get)) {
            const windowStartPos = this.windowTarget.scrollLeft;
            const items = this.itemTargets;
            let newPosition = 0;
            for (let i = items.length - 1; i >= 0; i -= 1) {
                const item = items[i];
                const itemWidth = item.offsetWidth;
                const itemStartPos = item.offsetLeft;
                const itemEndPos = itemStartPos + itemWidth;
                if (itemStartPos < windowStartPos) {
                    newPosition = itemEndPos - __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_carouselWindowWidth_get);
                    break;
                }
            }
            this.scrollTo(newPosition);
        }
    }
    goNext() {
        if (__classPrivateFieldGet(this, _default_1_instances, "a", _default_1_canGoNext_get)) {
            const windowStartPos = this.windowTarget.scrollLeft;
            const windowEndPos = windowStartPos + __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_carouselWindowWidth_get);
            const items = this.itemTargets;
            let newPosition = 0;
            for (let i = 0; i < items.length; i += 1) {
                const item = items[i];
                const itemStartPos = item.offsetLeft;
                if (itemStartPos + item.offsetWidth > windowEndPos) {
                    newPosition = itemStartPos;
                    break;
                }
            }
            this.scrollTo(newPosition);
        }
    }
    scrollTo(position) {
        this.windowTarget.scrollTo(position, 0);
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateControlsInternal).call(this);
    }
    itemTargetConnected() {
        this.updateControls();
    }
    itemTargetDisconnected() {
        this.updateControls();
    }
}
_default_1_instances = new WeakSet(), _default_1_isScrollable_get = function _default_1_isScrollable_get() {
    return this.windowTarget.scrollWidth > this.windowTarget.offsetWidth;
}, _default_1_carouselWindowWidth_get = function _default_1_carouselWindowWidth_get() {
    return this.element.clientWidth;
}, _default_1_updateControlsInternal = function _default_1_updateControlsInternal() {
    if (!__classPrivateFieldGet(this, _default_1_instances, "a", _default_1_isScrollable_get)) {
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_setPreviousControlVisible).call(this, false);
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_setNextControlVisible).call(this, false);
        return;
    }
    __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_setPreviousControlVisible).call(this, __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_canGoPrevious_get));
    __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_setNextControlVisible).call(this, __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_canGoNext_get));
}, _default_1_setPreviousControlVisible = function _default_1_setPreviousControlVisible(visible) {
    this.previousControlTarget.classList[visible ? 'remove' : 'add']('hide');
}, _default_1_setNextControlVisible = function _default_1_setNextControlVisible(visible) {
    this.nextControlTarget.classList[visible ? 'remove' : 'add']('hide');
}, _default_1_canGoPrevious_get = function _default_1_canGoPrevious_get() {
    return this.windowTarget.scrollLeft > 0;
}, _default_1_canGoNext_get = function _default_1_canGoNext_get() {
    const { windowTarget } = this;
    const windowPos = windowTarget.scrollLeft;
    const windowScrollWidth = windowTarget.scrollWidth;
    const windowWidth = windowTarget.offsetWidth;
    return windowScrollWidth > windowWidth && windowPos + windowWidth < windowScrollWidth;
};
default_1.targets = ['window', 'item', 'previousControl', 'nextControl'];
