var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _ProgressUpdateButtonController_instances, _ProgressUpdateButtonController_internalId, _ProgressUpdateButtonController_timeoutKey, _ProgressUpdateButtonController_librarySubscription, _ProgressUpdateButtonController_clickListener, _ProgressUpdateButtonController_cancelTimeout, _ProgressUpdateButtonController_unsubscribe, _ProgressUpdateButtonController_updateUI, _ProgressUpdateButtonController_perform;
import { Controller } from '@local-stimulus';
import { LibraryStatus } from '@javascript/library/constants';
import TimeoutManager from '@root/javascript/util/timeout-manager';
import uniqueId from '@javascript/util/unique-id';
import { LibraryEntryInputModel, } from '@root/user/library-entry-input-model';
const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
const CANVAS_TARGET_NAME = 'canvas';
const CANVAS_TARGET_DATASET_KEY = 'progressUpdateButtonTarget';
const $timeouts = new TimeoutManager();
function createSVGElement(qualifiedName) {
    return document.createElementNS(SVG_NAMESPACE, qualifiedName);
}
// Common
const commonFragment = (() => {
    const VIEW_BOX = '0 0 24 24';
    let instance;
    // Sprite sheet setup
    {
        const spriteSheet = createSVGElement('svg');
        const sheetDefs = createSVGElement('defs');
        const sheetSymbol = createSVGElement('symbol');
        const sheetPath = createSVGElement('path');
        sheetPath.setAttributeNS(null, 'd', 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z');
        spriteSheet.setAttribute('viewBox', VIEW_BOX);
        spriteSheet.style.display = 'none';
        sheetSymbol.setAttributeNS(null, 'id', 'icon:check-circle');
        sheetDefs.appendChild(sheetSymbol);
        sheetSymbol.appendChild(sheetPath);
        spriteSheet.append(sheetDefs);
        document.body.appendChild(spriteSheet);
    }
    return () => {
        if (!instance) {
            instance = document.createDocumentFragment();
            const canvas = createSVGElement('svg');
            canvas.setAttribute('viewBox', VIEW_BOX);
            const path = createSVGElement('use');
            path.setAttributeNS(null, 'href', '#icon:check-circle');
            canvas.dataset[CANVAS_TARGET_DATASET_KEY] = CANVAS_TARGET_NAME;
            // primaryPath.setAttribute('class', 'primary-path');
            canvas.appendChild(path);
            instance.appendChild(canvas);
        }
        return instance.cloneNode(true);
    };
})();
export default class ProgressUpdateButtonController extends Controller {
    constructor() {
        super(...arguments);
        _ProgressUpdateButtonController_instances.add(this);
        // declare maxEpisodesValue?: Number;
        // declare incrementByValue?: Number;
        _ProgressUpdateButtonController_internalId.set(this, uniqueId());
        _ProgressUpdateButtonController_timeoutKey.set(this, `${__classPrivateFieldGet(this, _ProgressUpdateButtonController_internalId, "f")}`);
        _ProgressUpdateButtonController_librarySubscription.set(this, void 0);
        _ProgressUpdateButtonController_clickListener.set(this, () => {
            __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_perform).call(this);
        });
    }
    initialize() {
        const { element } = this;
        element[this.identifier] = this;
        element.appendChild(commonFragment());
        __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_updateUI).call(this);
    }
    connect() {
        this.element.addEventListener('click', __classPrivateFieldGet(this, _ProgressUpdateButtonController_clickListener, "f"));
    }
    disconnect() {
        this.element.removeEventListener('click', __classPrivateFieldGet(this, _ProgressUpdateButtonController_clickListener, "f"));
        __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_cancelTimeout).call(this);
        __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_unsubscribe).call(this);
    }
    animeIdValueChanged(value /* , previousValue */) {
        __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_updateUI).call(this);
        if (value) {
            const { Viewer: { library } } = window;
            __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_unsubscribe).call(this);
            __classPrivateFieldSet(this, _ProgressUpdateButtonController_librarySubscription, library.changes$.subscribe((change) => {
                if (change.animeId === value) {
                    __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_updateUI).call(this, change.attributes);
                }
            }), "f");
        }
    }
    notBeforeValueChanged(value) {
        __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_cancelTimeout).call(this);
        if (value && value > Date.now()) {
            $timeouts.scheduleAt(__classPrivateFieldGet(this, _ProgressUpdateButtonController_timeoutKey, "f"), () => { __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_updateUI).call(this); }, +value);
        }
        else {
            __classPrivateFieldGet(this, _ProgressUpdateButtonController_instances, "m", _ProgressUpdateButtonController_updateUI).call(this);
        }
    }
}
_ProgressUpdateButtonController_internalId = new WeakMap(), _ProgressUpdateButtonController_timeoutKey = new WeakMap(), _ProgressUpdateButtonController_librarySubscription = new WeakMap(), _ProgressUpdateButtonController_clickListener = new WeakMap(), _ProgressUpdateButtonController_instances = new WeakSet(), _ProgressUpdateButtonController_cancelTimeout = function _ProgressUpdateButtonController_cancelTimeout() {
    const timeoutKey = __classPrivateFieldGet(this, _ProgressUpdateButtonController_timeoutKey, "f");
    if (timeoutKey) {
        $timeouts.cancel(__classPrivateFieldGet(this, _ProgressUpdateButtonController_timeoutKey, "f"));
    }
}, _ProgressUpdateButtonController_unsubscribe = function _ProgressUpdateButtonController_unsubscribe() {
    var _a;
    (_a = __classPrivateFieldGet(this, _ProgressUpdateButtonController_librarySubscription, "f")) === null || _a === void 0 ? void 0 : _a.unsubscribe();
}, _ProgressUpdateButtonController_updateUI = function _ProgressUpdateButtonController_updateUI(libraryEntry) {
    const { element } = this;
    if (this.notBeforeValue > Date.now()) {
        element.classList.add('hide');
        return;
    }
    const animeId = this.animeIdValue;
    const episodeNumber = this.episodeNumberValue;
    const { Viewer } = window;
    const currentEntry = libraryEntry || Viewer.library.get(animeId);
    const currentEpisodesWatched = currentEntry.episodes_watched;
    const currentStatus = currentEntry.status;
    element.title = `Set progress to ${episodeNumber}`;
    element.classList.toggle('hide', (!currentEpisodesWatched && currentEpisodesWatched !== 0)
        || currentEpisodesWatched >= episodeNumber
        || currentStatus === LibraryStatus.Completed
        || currentStatus === LibraryStatus.Skipping);
}, _ProgressUpdateButtonController_perform = function _ProgressUpdateButtonController_perform() {
    const animeId = this.animeIdValue;
    const { Viewer: { library } } = window;
    this.element.classList.add('hide');
    const initialModel = new LibraryEntryInputModel(library.get(animeId));
    const resultModel = initialModel.withChanges(initialModel.changeEpisodesWatched(this.episodeNumberValue || 1));
    return library.set(animeId, resultModel.toDelta());
};
ProgressUpdateButtonController.targets = [CANVAS_TARGET_NAME];
ProgressUpdateButtonController.values = {
    animeId: String,
    episodeNumber: Number,
    notBefore: Number,
    // maxEpisodes: Number,
    // incrementBy: Number,
};
