import { Controller } from '@local-stimulus';
import ViewerLanguageManager from '@root/javascript/util/viewer-language-manager';
const HIGHLIGHTED_ELEMENT = document.createElement('span');
HIGHLIGHTED_ELEMENT.classList.value = 'is-desired';
export default class default_1 extends Controller {
    connect() {
        const languagesToHighlight = ViewerLanguageManager.getAll();
        const listElement = this.listTarget;
        const newList = document.createDocumentFragment();
        let highlightedCount = 0;
        this.itemTargets.forEach((itemElement) => {
            const itemLanguageCode = itemElement.dataset.code.toLowerCase();
            if (languagesToHighlight.has(itemLanguageCode)) {
                highlightedCount += 1;
                const newElement = itemElement.cloneNode(false);
                const highlightedElement = HIGHLIGHTED_ELEMENT.cloneNode(false);
                highlightedElement.textContent = itemElement.textContent;
                newElement.appendChild(highlightedElement);
                newList.appendChild(newElement);
            }
            else if (itemLanguageCode === 'ja') {
                newList.appendChild(itemElement.cloneNode(true));
            }
        });
        if (highlightedCount > 0) {
            listElement.replaceChildren(newList);
        }
    }
}
default_1.targets = ['item', 'list'];
