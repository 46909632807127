var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_isExpanded;
import { Controller } from '@local-stimulus';
const HIDE_CLASS = 'hide';
const COLLAPSED_CLASS = 'expandable-text-collapsed';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        _default_1_isExpanded.set(this, void 0);
    }
    connect() {
        __classPrivateFieldSet(this, _default_1_isExpanded, this.data.get('expanded') !== 'false', "f");
        const cutoffHeight = +this.data.get('clampHeight') + +this.data.get('threshold');
        if (this.element.scrollHeight >= cutoffHeight) {
            this.collapse();
        }
    }
    get isExpanded() {
        return __classPrivateFieldGet(this, _default_1_isExpanded, "f");
    }
    set isExpanded(value) {
        __classPrivateFieldSet(this, _default_1_isExpanded, value, "f");
    }
    expand() {
        if (!this.isExpanded) {
            this.isExpanded = true;
            this.showMoreButtonTarget.classList.add(HIDE_CLASS);
            this.showLessButtonTarget.classList.remove(HIDE_CLASS);
            this.element.classList.remove(COLLAPSED_CLASS);
            this.expandableTarget.style.height = null;
        }
    }
    collapse() {
        if (this.isExpanded) {
            this.isExpanded = false;
            this.showMoreButtonTarget.classList.remove(HIDE_CLASS);
            this.showLessButtonTarget.classList.add(HIDE_CLASS);
            this.element.classList.add(COLLAPSED_CLASS);
            this.expandableTarget.style.height = `${this.data.get('clampHeight')}px`;
        }
    }
}
_default_1_isExpanded = new WeakMap();
default_1.targets = ['expandable', 'showMoreButton', 'showLessButton'];
