var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _countdownRunnerEntry, _timeoutKey;
import CollectionCountdownRunner from '@javascript/countdown/collection-countdown-runner';
import { Controller } from '@local-stimulus';
import TimeoutManager from '@root/javascript/util/timeout-manager';
import PartialCollectionCountdownRunner from '@root/javascript/countdown/partial-collection-countdown-runner';
const TIMEOUT_MANAGER = new TimeoutManager();
const COUNTDOWN_RUNNER = new CollectionCountdownRunner();
const PARTIAL_COUNTDOWN_RUNNER = new PartialCollectionCountdownRunner();
export default class extends Controller {
    constructor() {
        super(...arguments);
        _countdownRunnerEntry.set(this, void 0);
        _timeoutKey.set(this, window.crypto.randomUUID());
    }
    connect() {
        const { element } = this;
        const { dataset } = element;
        const { style } = dataset;
        let countdownRunnerEntry;
        if (style === 'short') {
            countdownRunnerEntry = PARTIAL_COUNTDOWN_RUNNER.addElement(element, dataset.label);
        }
        else {
            countdownRunnerEntry = COUNTDOWN_RUNNER.addElement(element, dataset.label);
        }
        __classPrivateFieldSet(this, _countdownRunnerEntry, countdownRunnerEntry, "f");
        const { timestamp } = countdownRunnerEntry;
        const timestampMillis = timestamp * 1000;
        const now = Date.now();
        if (timestampMillis > now) {
            dataset.period = 'future';
            TIMEOUT_MANAGER.scheduleAt(__classPrivateFieldGet(this, _timeoutKey, "f"), () => { dataset.period = 'past'; }, timestampMillis);
        }
        else {
            dataset.period = 'past';
        }
    }
    disconnect() {
        const countdownEntry = __classPrivateFieldGet(this, _countdownRunnerEntry, "f");
        const timeoutKey = __classPrivateFieldGet(this, _timeoutKey, "f");
        if (countdownEntry) {
            COUNTDOWN_RUNNER.remove(countdownEntry);
            PARTIAL_COUNTDOWN_RUNNER.remove(countdownEntry);
        }
        TIMEOUT_MANAGER.cancel(timeoutKey);
    }
}
_countdownRunnerEntry = new WeakMap(), _timeoutKey = new WeakMap();
