var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _default_1_drawerInitialized;
import { Controller } from '@local-stimulus';
import transitionEndEventName from '@root/javascript/util/html/transition-end-event-name';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        _default_1_drawerInitialized.set(this, false);
    }
    openDrawer() {
        if (!this.hasDrawerTarget) {
            return;
        }
        this.element.classList.add('is-off-canvas-open');
        if (!this.hasDrawerOverlayTarget) {
            const overlay = document.createElement('div');
            const overlayDataset = overlay.dataset;
            overlayDataset.target = `${this.identifier}.drawerOverlay`;
            overlayDataset.action = `click->${this.identifier}#closeDrawer`;
            overlay.classList.add('js-off-canvas-overlay', 'is-overlay-fixed', 'is-closable', 'is-visible');
            this.drawerTarget.insertAdjacentElement('afterend', overlay);
        }
        else {
            this.drawerOverlayTarget.classList.add('is-visible');
        }
        const drawerClassList = this.drawerTarget.classList;
        drawerClassList.remove('is-closed');
        drawerClassList.add('is-open');
        if (!__classPrivateFieldGet(this, _default_1_drawerInitialized, "f")) {
            __classPrivateFieldSet(this, _default_1_drawerInitialized, true, "f");
            drawerClassList.add('is-transition-overlap');
            this.contentTarget.classList.add('has-transition-overlap');
        }
    }
    closeDrawer() {
        this.element.classList.remove('is-off-canvas-open');
        if (this.hasDrawerOverlayTarget) {
            this.drawerOverlayTarget.classList.remove('is-visible');
        }
        if (this.hasDrawerTarget) {
            const drawer = this.drawerTarget;
            const drawerClassList = drawer.classList;
            const eventName = transitionEndEventName();
            drawerClassList.remove('is-open');
            if (eventName) {
                const callback = () => {
                    drawerClassList.add('is-closed');
                    drawer.removeEventListener(eventName, callback);
                };
                drawer.addEventListener(eventName, callback);
            }
            else {
                drawerClassList.add('is-closed');
            }
        }
    }
}
_default_1_drawerInitialized = new WeakMap();
default_1.targets = ['content', 'drawer', 'drawerOverlay'];
