let eventName;
let checked = false;
export default () => {
    if (!checked) {
        checked = true;
        const transitions = {
            transition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition: 'transitionend',
            OTransition: 'otransitionend',
        };
        const elem = document.createElement('div');
        Object.entries(transitions).forEach(([transition, event]) => {
            if (typeof elem.style[transition] !== 'undefined') {
                eventName = event;
            }
        });
    }
    return eventName;
};
