import LibraryEditorController, { n, LibraryEntryAttributeName, } from '../global_v2/abs-library-editor-controller';
function isFocused(element) {
    return document.activeElement === element;
}
export default class default_1 extends LibraryEditorController {
    connect() {
        super.connect();
        this.addSubscription(this.model$.subscribe((model) => {
            const { statusSelectTarget, rewatchesInputTarget, episodesWatchedInputTarget, finishedAtInputTarget, notesInputTarget, } = this;
            statusSelectTarget.value = model.status;
            const { errors } = model;
            this.ratingSelectTarget.value = n(model.rating);
            if (!isFocused(rewatchesInputTarget)) {
                rewatchesInputTarget.value = n(model.rewatches);
            }
            if (!isFocused(episodesWatchedInputTarget)) {
                episodesWatchedInputTarget.value = n(model.episodesWatched);
            }
            if (model.startedAt) {
                this.startedAtInputTarget.value = n(model.startedAt);
                finishedAtInputTarget.min = model.startedAt;
            }
            if (model.finishedAt) {
                finishedAtInputTarget.value = n(model.finishedAt);
            }
            finishedAtInputTarget.classList.toggle('is-invalid-input', errors.has(LibraryEntryAttributeName.FINISHED_AT));
            if (!isFocused(notesInputTarget)) {
                notesInputTarget.textContent = model.notes;
            }
            notesInputTarget.classList.toggle('is-invalid-input', errors.has(LibraryEntryAttributeName.NOTES));
            this.notifyInitialized();
        }));
        this.addSubscription(this.isProcessing$.subscribe((isProcessing) => {
            this.saveButtonTarget.classList.toggle('loading', isProcessing);
            this.closeButtonTarget.disabled = isProcessing;
            this.updateInputs();
        }));
        this.addSubscription(this.canSetStatus$.subscribe((canSetStatus) => {
            this.statusSelectTarget.disabled = !canSetStatus;
        }));
        this.addSubscription(this.canSetRewatches$.subscribe((canSetRewatches) => {
            this.rewatchesInputTarget.disabled = !canSetRewatches;
        }));
        this.addSubscription(this.canSetEpisodesWatched$.subscribe((canSetEpisodesWatched) => {
            this.episodesWatchedInputTarget.disabled = !canSetEpisodesWatched;
            this.episodeIncrementButtonTarget.disabled = !canSetEpisodesWatched;
        }));
        this.addSubscription(this.episodesWatchedConstraints$.subscribe(([min, max]) => {
            const { episodesWatchedInputTarget } = this;
            episodesWatchedInputTarget.min = `${min}`;
            episodesWatchedInputTarget.max = `${max}`;
        }));
        this.addSubscription(this.canRate$.subscribe((canRate) => {
            this.ratingSelectTarget.disabled = !canRate;
        }));
        this.addSubscription(this.canSetStartedAt$.subscribe((canSetStartedAt) => {
            this.startedAtInputTarget.disabled = !canSetStartedAt;
        }));
        this.addSubscription(this.canSetFinishedAt$.subscribe((canSetFinishedAt) => {
            this.finishedAtInputTarget.disabled = !canSetFinishedAt;
        }));
        this.addSubscription(this.canSetNotes$.subscribe((canSetNotes) => {
            this.notesInputTarget.disabled = !canSetNotes;
        }));
        this.addSubscription(this.notesCharactersRemaining$.subscribe((notesCharactersRemaining) => {
            const { notesCharacterLimitTarget } = this;
            const { percent } = notesCharactersRemaining;
            const { classList: charactersRemainingClassList } = notesCharacterLimitTarget;
            notesCharacterLimitTarget.textContent = `${notesCharactersRemaining.remaining} / ${notesCharactersRemaining.limit}`;
            charactersRemainingClassList.toggle('text-alert', percent >= 1);
            charactersRemainingClassList.toggle('text-warning', percent >= 0.85 && percent < 1);
        }));
        this.addSubscription(this.hasUnsavedChanges$.subscribe((changed) => {
            var _a;
            const { closeButtonTarget } = this;
            closeButtonTarget.textContent = changed ? 'Discard' : 'Cancel';
            closeButtonTarget.classList.toggle('alert', changed);
            (_a = this.messageBoxTarget.querySelector('[data-id="unsaved-changes"]')) === null || _a === void 0 ? void 0 : _a.remove();
        }));
        this.addSubscription(this.canSave$.subscribe((canSave) => {
            this.saveButtonTarget.disabled = !canSave;
        }));
        this.addSubscription(this.canDelete$.subscribe((canDelete) => {
            const { deleteButtonTarget } = this;
            deleteButtonTarget.classList.toggle('alert', canDelete);
            deleteButtonTarget.disabled = !canDelete;
        }));
    }
    showDatalossWarning() {
        this.showMessage(this.datalossWarningTemplateTarget.content);
    }
    setupUI() {
        this.closeAfterSaveCheckboxTarget.checked = this.closeAfterSaving;
        this.updateTitle();
    }
    close() {
        this.element.remove();
    }
    showUnsavedChangesWarning() {
        const message = this.unsavedChangesWarningTemplateTarget.content;
        this.showMessage(message);
    }
    updateTitle() {
        const { titleTarget } = this;
        titleTarget.title = this.animeTitle;
        titleTarget.textContent = this.animeTitle;
        titleTarget.href = `/anime/${this.animeId}`;
    }
    hideStatusChooser(e) {
        const { activeElement } = document;
        if (activeElement instanceof HTMLElement && activeElement.classList.contains('dropdown-content')) {
            activeElement.blur();
        }
    }
}
default_1.targets = [
    'box', 'title',
    'messageBox', 'unsavedChangesWarningTemplate', 'datalossWarningTemplate',
    'statusSelect',
    'episodesWatchedInput', 'episodeIncrementButton',
    'ratingSelect',
    'rewatchesInput',
    'notesInput', 'notesCharacterLimit',
    'startedAtInput',
    'finishedAtInput',
    'saveButton', 'closeAfterSaveCheckbox', 'closeButton', 'deleteButton',
];
