var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _default_1_instances, _default_1_currentMenuName, _default_1_dismissedNotices, _default_1_closeAllMenusButCurrent, _default_1_conditionallyCloseAllMenus, _default_1_eachMenuTrigger;
import { Controller } from '@local-stimulus';
import { PreferenceKeys } from '@root/user/global-preferences';
import localStorageUtils from '@utils/local-storage-utils';
const DISMISSED_NOTICES_KEY = 'dismissed-notices';
export default class default_1 extends Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        _default_1_currentMenuName.set(this, null);
        _default_1_dismissedNotices.set(this, localStorageUtils.get(DISMISSED_NOTICES_KEY, []));
    }
    connect() {
        document.addEventListener('click', (event) => {
            __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_conditionallyCloseAllMenus).call(this, event.target);
        }, false);
        if ('ontouchstart' in document.documentElement) {
            document.addEventListener('touchstart', (event) => {
                __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_conditionallyCloseAllMenus).call(this, event.target);
            }, false);
        }
        if (this.hasDarkModeSwitchTarget) {
            this.darkModeSwitchTarget.checked = window.Viewer.preferences.getTyped(PreferenceKeys.NightMode, false);
        }
    }
    setCurrentMenu(e) {
        const target = e.target;
        const menuName = target.value;
        if (target.checked) {
            __classPrivateFieldSet(this, _default_1_currentMenuName, menuName, "f");
        }
        else if (__classPrivateFieldGet(this, _default_1_currentMenuName, "f") === menuName) {
            __classPrivateFieldSet(this, _default_1_currentMenuName, null, "f");
        }
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_closeAllMenusButCurrent).call(this);
    }
    closeAllMenus() {
        __classPrivateFieldSet(this, _default_1_currentMenuName, null, "f");
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_eachMenuTrigger).call(this, (trigger) => {
            trigger.checked = false;
        });
    }
    toggleDarkMode() {
        window.Viewer.preferences.toggleNightMode(true);
    }
    dismissNotice(e) {
        const notice = e.target.closest('[data-header-target="notice"]');
        __classPrivateFieldGet(this, _default_1_dismissedNotices, "f").push(notice.dataset.id);
        localStorageUtils.set(DISMISSED_NOTICES_KEY, __classPrivateFieldGet(this, _default_1_dismissedNotices, "f"));
        notice.remove();
    }
}
_default_1_currentMenuName = new WeakMap(), _default_1_dismissedNotices = new WeakMap(), _default_1_instances = new WeakSet(), _default_1_closeAllMenusButCurrent = function _default_1_closeAllMenusButCurrent() {
    const currentMenuName = __classPrivateFieldGet(this, _default_1_currentMenuName, "f");
    __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_eachMenuTrigger).call(this, (trigger) => {
        if (trigger.value !== currentMenuName) {
            trigger.checked = false;
        }
    });
}, _default_1_conditionallyCloseAllMenus = function _default_1_conditionallyCloseAllMenus(targetElement) {
    if (!this.element.contains(targetElement)) {
        this.closeAllMenus();
    }
}, _default_1_eachMenuTrigger = function _default_1_eachMenuTrigger(callback) {
    const triggers = this.menuTriggerTargets;
    const { length } = triggers;
    for (let i = 0; i < length; i += 1) {
        callback(triggers[i]);
    }
};
default_1.targets = ['menuTrigger', 'darkModeSwitch'];
