import DomJson from '@root/util/dom-json';
import LocalStorageUtils from '@local-storage-utils';
import ModalController from '@root/controllers/global/modal-controller';
const MarkFiltersData = DomJson('mark_filters');
export default class default_1 extends ModalController {
    open() {
        LocalStorageUtils.remove('has-opened-timetable-preferences-modal');
        this.checkboxTargets.forEach((element) => {
            element.checked = MarkFiltersData[element.getAttribute('name')];
        });
        this.progressBarTarget.classList.remove('visible');
        super.open();
    }
    save() {
        this.progressBarTarget.classList.add('visible');
        const values = this.checkboxTargets.reduce((result, element) => {
            result[element.getAttribute('name')] = element.checked;
            return result;
        }, {});
        const changedCount = window.Viewer.markFilter(this.data.get('scope')).setMultiple(values);
        if (changedCount > 0) {
            this.actionButtonTargets.forEach((element) => {
                element.disabled = true;
            });
            // We're using #assign here since window.location.reload caused issues after the CloudFlare
            // challenge page due to it being a POST request.
            window.location.assign(window.location.href);
        }
        else {
            this.softClose();
        }
    }
}
default_1.targets = [
    'progressBar', 'checkbox', 'actionButton',
];
