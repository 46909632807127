var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _lazyLoader;
import '@polyfills';
import { Controller } from '@local-stimulus';
export default class extends Controller {
    constructor() {
        super(...arguments);
        _lazyLoader.set(this, this.application.ioLazyLoader);
    }
    connect() {
        __classPrivateFieldGet(this, _lazyLoader, "f").observe(this.element);
    }
    disconnect() {
        __classPrivateFieldGet(this, _lazyLoader, "f").unobserve(this.element);
    }
}
_lazyLoader = new WeakMap();
