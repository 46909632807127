import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@polyfills';
import { loadFromContext } from '@local-stimulus';
import Viewer from '@root/javascript/viewer/model';
import LibraryEditor from '@root/modal/library-editor-modal';
import { removeQueryParams } from '@root/javascript/util/url-utils';
import { CloudFlareParams } from '@root/javascript/cloudflare';
import Rails from '@rails/ujs';
Object.defineProperties(window, {
    Viewer: {
        value: new Viewer(),
        writable: false,
    },
    LibraryEditor: {
        value: LibraryEditor,
        writable: false,
    },
});
loadFromContext(require.context('controllers/global', true, GLOBAL_REQUIRE_CONTEXT_REGEX));
document.body.classList.add('js-enabled');
// NOTE: Have this be geofenced
// Save 40% on tickets to screenings at the Animiation is Film festival Oct 18-20 with code: LIVECHART.ME
// if (window.location.pathname !== '/timetable' && !LocalStorageUtils.has('has-opened-timetable-preferences-modal')) {
//   HeaderNotice.show({
//     id: 'timetable-preferences',
//     message: 'NEW: Choose which of your anime appear in the Timetable using Timetable Preferences',
//     href: '/timetable#highlight-preferences',
//   });
// }
// Only show the banner to users running on Android.
// if (/(android)/i.test(navigator.userAgent)) {
//   LiveChart.addNotice({
//     id: 'android-app',
//     message: 'Get push notifications with LiveChart.me for Android, available on Google Play™!',
//     href: 'https://play.google.com/store/apps/details?id=me.livechart.android',
//     oncePerSession: true
//   });
// }
// Remove fragment added by Facebook OAuth
const { location } = window;
if (location.hash === '#_=_') {
    location.hash = '';
}
// This is to strip CloudFlare's challenge parameters from the query string.
if (location.search.includes(CloudFlareParams.CAPTCHA)
    || location.search.includes(CloudFlareParams.JS_CHALLENGE)) {
    const strippedUrl = removeQueryParams(location.href, CloudFlareParams.CAPTCHA, CloudFlareParams.JS_CHALLENGE);
    window.history.replaceState({}, document.title, strippedUrl);
}
Rails.start();
