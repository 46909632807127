import { Controller } from '@local-stimulus';
export default class default_1 extends Controller {
    revealText() {
        if (this.hasWarningTarget) {
            this.warningTarget.remove();
        }
        this.element.classList.remove('is-spoiler-masked');
    }
}
default_1.targets = ['warning'];
