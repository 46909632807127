var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _storage;
import { Controller } from '@local-stimulus';
import BrowserStorageUtils from '@browser-storage-utils';
import CookieUtils from '@utils/cookie-utils';
var Store;
(function (Store) {
    Store["Local"] = "@local";
    Store["Session"] = "@session";
    Store["Cookie"] = "@cookie";
})(Store || (Store = {}));
export default class extends Controller {
    constructor() {
        super(...arguments);
        _instances.add(this);
    }
    connect() {
        __classPrivateFieldGet(this, _instances, "m", _storage).call(this, (store, _, prefixedKey) => {
            if ((store === Store.Local && BrowserStorageUtils.localStorage.has(prefixedKey))
                || (store === Store.Session && BrowserStorageUtils.sessionStorage.has(prefixedKey))
                || (store === Store.Cookie && CookieUtils.get(prefixedKey, null) !== null)) {
                this.dismiss();
            }
        });
        this.element.classList.remove('hide');
    }
    dismiss() {
        this.element.remove();
        __classPrivateFieldGet(this, _instances, "m", _storage).call(this, (store, _, prefixedKey) => {
            if (store === Store.Local) {
                BrowserStorageUtils.localStorage.set(prefixedKey, Date.now());
            }
            else if (store === Store.Session) {
                BrowserStorageUtils.sessionStorage.set(prefixedKey, Date.now());
            }
            else if (store === Store.Cookie) {
                CookieUtils.set(prefixedKey, Date.now());
            }
        });
    }
}
_instances = new WeakSet(), _storage = function _storage(cb) {
    const storage = this.data.get('storage');
    if (storage) {
        const [store, key] = storage.split(':', 2);
        const prefixedKey = `dismissed:${key}`;
        cb(store, key, prefixedKey);
    }
};
